import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: #FDF8EE;

  @media (min-width: ${breakpoints.md}){
    min-height: 464px;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: 504px;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 648px;
  }

  h1 {
    font-size: 32px;
    line-height: 35px;

    @media (min-width: ${breakpoints.md}){
      font-size: 24px;
      line-height: 26px;
    }

    @media (min-width: ${breakpoints.lg}){
      font-size: 40px;
      line-height: 44px;
    }

    @media (min-width: ${breakpoints.xl}){
      font-size: 48px;
      line-height: 52px;
    }
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: #ff7a00;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  margin-top: 25px;

  @media (min-width: ${breakpoints.md}){
    margin-top: 40px;
    max-width: 310px;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 440px;
  }

  &:focus{
    outline: none;
  }
`

export const ButtonLink = styled.a`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #ff7a00;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.md}){
    max-height: 255px;
    margin-top: 40px;
  }

  &:focus, &:hover{
    outline: none;
    text-decoration: none;
    color: #fff;
    opacity: 0.9;
  }
`
