import styled from 'styled-components'
import { green, white, orange } from '../../../../../styles/colors'
import { primary } from '../../../../../styles/newColors'

export const Section = styled.section`
  background-color: #FDF8EE;
  overflow-x: hidden;
  
  .carousel-full-width {
    overflow: visible;
  }

  .react-multiple-carousel__arrow--right {
    right: 180px;
  }
  .react-multiple-carousel__arrow--left {
    left: 180px;
  }
  .react-multi-carousel-dot {
      button {
        background: ${orange.extra};
      }
    }
  
    .react-multi-carousel-dot--active {
      button {
        background: ${orange.extra};
      }
    }
`

export const ButtonLink = styled.a`
  background: ${primary[500]};
  border: none;
  border-radius: 8px;
  width: 80%;
  color: ${white};
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  height: 40px;
  margin-top: 32px;
  box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.08);
  display: flex;
  align-items: center;
  justify-content:center;

  &:hover {
    color: ${white};
  }

  &:focus{
    outline: none;
  }
`

export const Cashback = styled.div`
  background-color: ${green[400]};
  height: 36px;
  border-radius: 10px 10px 0 0;
`

export const Partner = styled.div`
  background-color: ${white};
  height: 240px;

  a {
    position: absolute;
    bottom: 20px;
  }
`
