import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from '../../../../../styles/colors'

export const Section = styled.section`
  background-color: ${orange[500]};

  height: 650px;
  
  @media (min-width: ${breakpoints.md}){
    height: auto;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: #ff7a00;
  color: #fff;
  font-size: 14;
  line-height: 17px;
  font-weight: 600;
  border: none;
  margin-top: 25px;

  @media (min-width: ${breakpoints.md}){
    max-height: 255px;
    margin-top: 40px;
    max-width: 255px;
  }

  &:focus{
    outline: none;
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background: ${white};
  color: #EA7100;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  position: relative;
  top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover{
    outline: none;
    text-decoration: none;
    color: #EA7100;
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.md}){
    max-height: 255px;
    margin-top: 20px;
    
  }
`
