import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './_sections/hero/_index'
import YouPlanYourTrip from './_sections/your-next-trip-starts-here/_index'
import IncrediblePartnerships from './_sections/incredible-partnerships/_index'
import Cashback from './_sections/cashback/_index'
import TravelInsurance from './_sections/travel-insurance/_index'
import YourCompleteJourney from './_sections/your-complete-journey/_index'
import TravelTips from './_sections/travel-tips/_index'
import FaqInterTravel from './_sections/faq/_index'
import QrCode from './assets/image/qr-code-viagens.png'

import { Modal } from 'src/components/Modal'
import HomeApp from './_components/_modal/_index'
import useDomReady from 'src/hooks/window/useDomReady'

const InterTravel = () => {
  const [ isModal, setIsModal ] = useState(false)

  const domReady = useDomReady()
  const homeApp = domReady && (
    <Modal isModalOpen={isModal} setIsModalOpen={setIsModal} locationToRender={document.body}>
      <HomeApp isModal={isModal} setIsModal={setIsModal} qrCode={QrCode} />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {homeApp}
      <Hero setIsModal={setIsModal} />
      <YouPlanYourTrip setIsModal={setIsModal} />
      <IncrediblePartnerships />
      <Cashback setIsModal={setIsModal} />
      <TravelInsurance setIsModal={setIsModal} />
      <YourCompleteJourney />
      <TravelTips />
      <FaqInterTravel />
    </Layout>
  )
}

export default InterTravel
