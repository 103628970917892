import styled from 'styled-components'
import { primary } from '../../../../../styles/newColors'
import { white } from '../../../../../styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`  
  .react-multi-carousel-track {
    padding: 0;
  }
  .react-multi-carousel-dot-list {
    bottom: 100px;
    @media (min-width: ${breakpoints.md}){
      bottom: 140px;
    }
  }
  .react-multiple-carousel__arrow {
    bottom: 90px;
   @media (min-width: ${breakpoints.md}){
      bottom: 130px;
    }
  }
  .react-multi-carousel-dot--active {
    button {
      background: #FF8700;
    }
  }
  .react-multiple-carousel__arrow--right {
    @media (min-width: ${breakpoints.md}){
      right: 102px;
    }
    @media (min-width: ${breakpoints.lg}){
      right: 232px;
    }
    @media (min-width: ${breakpoints.xl}){
      right: 432px;
    }
    @media (min-width: ${breakpoints.xxl}){
      right: 521px;
    }
  }
  .react-multiple-carousel__arrow--left {
    @media (min-width: ${breakpoints.md}){
      left: 104px;
    }
    @media (min-width: ${breakpoints.lg}){
      left: 230px;
    }
    @media (min-width: ${breakpoints.xl}){
      left: 432px;
    }
    @media (min-width: ${breakpoints.xxl}){
      left: 517px;
    }
  }
`

export const Section = styled.section`
  min-height: 576px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -260px 0;

  @media (min-width: ${breakpoints.md}){
    background-position: top center;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 100vh;
  }
`

export const ButtonLink = styled.a`
  background: ${primary[500]};
  border: none;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  height: 48px;
  margin-top: 32px;
  box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.08);
  display: flex;
  align-items: center;
  justify-content:center;

  &:hover {
    background: ${primary[500]};
    color: ${white};
  }

  @media (min-width: ${breakpoints.md}){
    width: 553px;
  }

  &:focus{
    outline: none;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: #ff7a00;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;
  margin-top: 25px;

  @media (min-width: ${breakpoints.md}){
    margin-top: 40px;
    max-width: 310px;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 440px;
  }

  &:focus{
    outline: none;
  }
`
