import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import TripJSON from '../../assets/data/trips.json'
import * as S from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type TripsProps = {
  title: string;
  description: string;
  image: string;
}

type ModalProps = {
  setIsModal: Function;
}

const Trips = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_5',
      element_action: 'click button',
      element_name: 'Viajar com economia',
      c_page: window.location.href,
    })
  }

  return (
    <S.Container>
      <DefaultCarousel
        sm={{ items: 1 }}
        md={{ items: 1 }}
        lg={{ items: 1 }}
        xl={{ items: 1 }}
        autoPlay
        infinite
        autoPlaySpeed={4000}
      >
        {
          TripJSON.map((item: TripsProps) => (
            <S.Section className='py-5 d-flex align-items-center' style={{ backgroundImage: `url(${item.image})` }} key={item.image}>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12'>
                    <div className='flex-column justify-content-center'>
                      <h2 className='fs-24 fs-md-56 lh-26 lh-md-60 font-citrina text-white text-center'>{item.title}</h2>
                      <p className='fs-14 fs-md-18 lh-19 lh-md-22 text-white text-center mb-3'>{item.description}</p>
                      <div className='d-flex justify-content-center'><S.Button onClick={() => handleClick()} className='d-none d-md-block'>Escolher minha viagem</S.Button></div>
                      <S.ButtonLink
                        href='https://inter-co.onelink.me/Qyu7/ewmg8eox'
                        target='_blank'
                        rel='noreferrer'
                        title='Escolher minha viagem'
                        className='d-md-none'
                        onClick={() => sendDatalayerEvent({
                          section: 'dobra_5',
                          element_action: 'click button',
                          element_name: `Escolher minha viagem - ${item.title}`,
                          c_page: window.location.href,
                        })}
                      >
                        Escolher minha viagem
                      </S.ButtonLink>
                    </div>
                  </div>
                </div>
              </div>
            </S.Section>
          ))}
      </DefaultCarousel>
    </S.Container>
  )
}

export default Trips
