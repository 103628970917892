import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  @media (min-width: ${breakpoints.md}){
    min-height: 484px;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: 502px;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 508px;
  }

  .p-left {
    position: relative;

    @media (min-width: ${breakpoints.md}){
     padding: 0 40px 0 450px
    }
  }

  .scroll {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    
    @media (min-width: ${breakpoints.lg}){
      overflow: hidden;
      white-space: normal;
    }
  }
`
