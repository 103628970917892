import React from 'react'

// Components
import FAQ from 'src/components/Faq'

// Data
import pageContext from '../../pageContext.json'

// Styles
import * as S from './style'

const FaqInterTravel = () => {
    return (
      <S.Section className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='mb-4'>
              <h2 className='fs-24 lh-30 fs-xl-40 lh-xl-50 text-grayscale--400 font-citrina'>Perguntas frequentes</h2>
            </div>

            <FAQ answerData={pageContext.structuredData.faq} columns={{ xl: 1, lg: 1 }} />

          </div>
        </div>
      </S.Section>
    )
}

export default FaqInterTravel
