import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ModalProps = {
  setIsModal: Function;
}

const Hero = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'Quer viajar barato? Aqui tem tudo pra sua viagem com muito cashback',
      element_action: 'click button',
      element_name: 'Viajar com economia',
      c_page: window.location.href,
    })
  }
  return (
    <S.Section
      className='py-5 d-flex align-items-end align-items-md-center'
      role='img'
      aria-label='Paisagem de uma praia'
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-5'>
            {windowWidth >= WIDTH_MD && (
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-viagens-ferias/image.webp'
                altDescription=''
                arrayNumbers={[ 336, 336, 376, 456 ]}
                className='mx-auto'
              />
            )}
          </div>
          <div className='col-12 col-md-6 offset-md-1'>
            <h1 className='lh-35 text-grayscale--500 font-citrina fw-600 mt-5 mb-3 mt-md-0'>
              Quer viajar barato? Aqui tem tudo pra sua viagem com muito cashback
            </h1>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 text-grayscale--400'>
              <span className='fw-600'>Passagens aéreas  e de ônibus, hospedagens e aluguel de carros</span> com a economia de receber dinheiro de volta.
            </p>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 text-grayscale--400'>
              Resolva sua viagem com mais economia e segurança direto pelo Super App do Inter
            </p>
            <S.Button onClick={() => handleClick()} className='d-none d-md-block'>Viajar com economia</S.Button>
            <S.ButtonLink
              className='d-md-none'
              href='https://inter-co.onelink.me/Qyu7/ewmg8eox'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_1',
                section_name: 'Quer viajar barato? Aqui tem tudo pra sua viagem com muito cashback',
                element_action: 'click button',
                element_name: 'Viajar com economia',
                c_page: window.location.href,
              })}
            >
              Viajar com economia
            </S.ButtonLink>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
