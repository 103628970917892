import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from '../../../../../hooks/window/useWidth'

import partnershipsJSON from '../../assets/data/partnerships.json'
import * as S from './style'

const IncrediblePartnerships = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)

  type PartnershipsProps = {
    cta?: string;
    ctaLink: string;
    image: string;
    partner: string;
    width: number[];
    height: number[];
  }

  return (
    <S.Section className='pt-5 py-lg-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-40 lh-28 lh-md-48 text-grayscale--500 fw-600 mb-4 text-center font-citrina'>Parcerias incríveis pra você viajar ganhando cashback</h2>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 50 }}
              lg={{ items: 3, partialVisibilityGutter: 50 }}
              xl={{ items: 4, partialVisibilityGutter: 40 }}
              containerClass='carousel-full-width'
              removeArrowOnDeviceType={[ 'mobile', 'tablet', 'desktop' ]}
              notShowDots={windowWidth <= 1024}
            >
              {
                partnershipsJSON.map((item: PartnershipsProps) => (
                  <div key={item.image} className='mr-4'>
                    <S.Cashback className='d-flex align-items-center justify-content-center fs-18 lh-21 text-white fw-700'>3% de cashback</S.Cashback>
                    <S.Partner className='d-flex justify-content-center align-items-center'>
                      <ImageWebp
                        pathSrc={item.image}
                        altDescription={item.partner}
                        arrayNumbers={item.width}
                        arrayNumbersHeight={item.height}
                        className='mx-auto mb-5'
                      />
                      <S.ButtonLink
                        href={item.ctaLink}
                        target='_blank'
                        rel='noreferrer'
                        title='Ganhar cashback'
                        className='mx-auto'
                        onClick={() => sendDatalayerEvent({
                          section: 'dobra_4',
                          section_name: 'Parcerias incríveis pra você viajar ganhando cashback',
                          element_action: 'click button',
                          element_name: `Ganhar cashback - ${item.partner}`,
                          redirect_url: item.ctaLink,
                          c_page: window.location.href,
                        })}
                      >
                        Ganhar cashback
                      </S.ButtonLink>
                    </S.Partner>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default IncrediblePartnerships
