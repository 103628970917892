import React from 'react'
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

type ModalProps = {
  setIsModal: Function;
}

const YourNextTripStartsHere = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name: 'Sua próxima viagem começa aqui',
      element_action: 'click button',
      element_name: 'Pesquisar minha viagem',
      c_page: window.location.href,
    })
  }
  return (
    <S.Section
      className='py-5 d-flex align-items-md-center align-items-end'
      role='img'
      aria-label='Desenho de uma mala de viagem'
    >
      <div className='container'>
        <div className='row mb-3'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-40 fs-lg-48 fs-xl-40 lh-28 lh-md-48 lh-lg-52 lh-xl-50 text-grayscale--500 fw-600 text-center font-citrina mb-4'>Sua próxima viagem começa aqui</h2>
            <p className='fs-16 lh-19 text-grayscale--400 text-center'>Faça uma escolha financeira inteligente e com mais conveniência pra viajar. Programe toda sua viagem com o Inter e ganhe cashback</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6 mb-3 mb-md-4'>
            <S.Card className='bg-grayscale--100 d-flex justify-content-between align-items-center px-4 pr-md-2 py-5 rounded-4'>
              <div>
                <p className='fs-18 fs-lg-24 lh-21 lh-lg-29 fw-600 text-grayscale--500'>Aluguel de carro</p>
                <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--400'><span className='d-block'>Todos os tipos de</span> veículos disponíveis para você.</p>
              </div>
              <div>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-viagens-carro-icon/image.webp'
                  altDescription=''
                  arrayNumbers={[ 149, 149, 161, 247 ]}
                />
              </div>
            </S.Card>
          </div>
          <div className='col-12 col-md-6 mb-3 mb-md-4'>
            <S.Card className='bg-grayscale--100 d-flex justify-content-between align-items-center px-4 py-5 rounded-4'>
              <div>
                <p className='fs-18 fs-lg-24 lh-21 lh-lg-29 fw-600 text-grayscale--500'>Hospedagens</p>
                <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--400'><span className='d-block'>Fique nos melhores</span><span className='d-block'>hotéis e resorts do</span> mundo.</p>
              </div>
              <div>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-viagens-campainha-icon/image.webp'
                  altDescription=''
                  arrayNumbers={[ 119, 119, 130, 162 ]}
                />
              </div>
            </S.Card>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <S.Card className='bg-grayscale--100 d-flex justify-content-between align-items-center px-4 pr-md-2 py-5 rounded-4'>
              <div>
                <p className='fs-18 fs-lg-24 lh-21 lh-lg-29 fw-600 text-grayscale--500'>Passagens</p>
                <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--400'><span className='d-block'>Garanta passagens de</span> avião ou de ônibus.</p>
              </div>
              <div>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-viagens-aviao-icon/image.webp'
                  altDescription=''
                  arrayNumbers={[ 135, 135, 135, 197 ]}
                />
              </div>
            </S.Card>
          </div>
          <div className='col-12 col-md-6'>
            <S.Card className='bg-grayscale--100 d-flex justify-content-between align-items-center px-4 pr-md-0 py-5 rounded-4'>
              <div>
                <p className='fs-18 fs-lg-24 lh-21 lh-lg-29 fw-600 text-grayscale--500'>Pacote de viagem</p>
                <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--400'><span className='d-block'>Economize nas maiores</span> agências de viagens do Brasil.</p>
              </div>
              <div>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/lp-viagens-malas/image.webp'
                  altDescription=''
                  arrayNumbers={[ 145, 135, 201, 201 ]}
                />
              </div>
            </S.Card>
          </div>
          <div className='col-12 d-flex justify-content-center mt-md-3'>
            <S.Button onClick={() => handleClick()} className='d-none d-md-block'>Pesquisar minha viagem</S.Button>
            <S.ButtonLink
              className='d-md-none'
              href='https://inter-co.onelink.me/Qyu7/ewmg8eox'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_2',
                section_name: 'Sua próxima viagem começa aqui',
                element_action: 'click button',
                element_name: 'Pesquisar minha viagem',
                redirect_url: 'https://inter-co.onelink.me/Qyu7/ewmg8eox',
                c_page: window.location.href,
              })}
            >
              Pesquisar minha viagem
            </S.ButtonLink>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default YourNextTripStartsHere
