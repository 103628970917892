import React from 'react'
import * as S from './style'
import CardMobile from './components/CardMobile'
import CardLg from './components/CardLg'
import CardXl from './components/CardXl'

const YourCompleteJourney = () => {
  return (
    <S.Section className='py-5 content'>
      <div className='container'>
        <div className='row mb-3'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-48 lh-26 lh-md-52 text-grayscale--500 text-center font-citrina mb-4'>Sua viagem completa</h2>
            <p className='fs-14 fs-md-16 lh-16 lh-md-19 text-grayscale--500 text-center'>No Super App, você encontra tudo o que precisa pra sua viagem, do início ao fim. É prático, seguro e ainda te ajuda a economizar. </p>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='d-lg-none scroll'>
            <CardMobile />
          </div>
          <div className='d-none d-lg-block d-xl-none'>
            <CardLg />
          </div>
          <div className='d-none d-xl-block'>
            <CardXl />
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default YourCompleteJourney
