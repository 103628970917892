import React from 'react'
import CashbackCalculation from '../../_components/CashbackCalculation/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from "src/hooks/window/useWidth"
import { WIDTH_MD } from "src/styles/breakpoints"

import * as S from './style'

type ModalProps = {
  setIsModal: Function;
}

const Cashback = ({ setIsModal }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const handleClick = () => {
    setIsModal(true)
    sendDatalayerEvent({
      section: 'dobra_4',
      section_name: 'Calcule o seu cashback',
      element_action: 'click button',
      element_name: 'Garantir cashback',
      c_page: window.location.href,
    })
  }

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 pr-lg-0'>
            <h2 className='fs-24 fs-md-40 fs-xl-48 lh-26 lh-md-44 lh-xl-52 text-white mb-3 fw-700 font-citrina'>Calcule o seu cashback</h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-white'>Na hora de viajar, cada economia conta, não é mesmo? Faça a simulação e confira quanto dá pra economizar ganhando dinheiro de volta na sua próxima viagem.</p>
            {width >= WIDTH_MD && (
              <S.ButtonLink
                target='_blank'
                rel='noreferrer'
                onClick={() => handleClick()}
                className='cursor-pointer'
              >
                Garantir cashback
              </S.ButtonLink>
            )}
          </div>
          <div className='col-12 col-md-6 offset-lg-1 col-xl-5'>
            <CashbackCalculation />
            {width < WIDTH_MD && (
              <S.ButtonLink
                href='https://inter-co.onelink.me/Qyu7/ewmg8eox'
                target='_blank'
                rel='noreferrer'
              >
                Garantir cashback
              </S.ButtonLink>
            )}
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Cashback
