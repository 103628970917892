import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  @media (min-width: ${breakpoints.md}){
    min-height: 420px;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 476px;
  }
`

export const Button = styled.button`
  background: #ff7a00;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  height: 48px;

  @media (min-width: ${breakpoints.md}){
    max-width: 456px;
  }
  @media (min-width: ${breakpoints.lg}){
    max-width: 655px;
  }
  @media (min-width: ${breakpoints.xl}){
    max-width: 572px;
  }

  &:focus{
    outline: none;
  }
`
export const ButtonLink = styled.a`
  background: #ff7a00;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  height: 40px;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}){
    margin-top: 32px;
    max-width: 239px;

  }

  @media (min-width: ${breakpoints.lg}){
    margin-top: 32px;
    max-width: 296px;

  }

  @media (min-width: ${breakpoints.xl}){
    max-width: 360px;
  }

  &:focus, &:hover{
    outline: none;
    text-decoration: none;
    color: #fff;
    opacity: 0.9;
  }
`

export const Card = styled.div`
  height: 282px;
`
